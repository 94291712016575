<template lang="">
  <li class="list-group-item list-skeleton"></li>
</template>
<script>
export default {};
</script>
<style lang="css">
.list-skeleton {
  opacity: 0.7;
  margin-top: 5px;
  min-height: 60px;
  animation: skeleton-loading 0.6s infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
