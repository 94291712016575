<template>
  <Navbar />
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-md-2 col-sm-12 fixed-left">
        <ul class="list-group" v-if="!loadingCategories">
          <li class="list-group-item" @click.prevent="() => sortBy(0)">
            <a class="btn btn-sm btn-link w-100 text-left" href="#all">All</a>
          </li>
          <li
            class="list-group-item"
            v-for="c in categories"
            :key="c.id"
            @click.prevent="() => sortBy(c.id)"
          >
            <a class="btn btn-sm btn-link w-100 text-left" href="#cathegories"
              >{{ c.name }} <span>({{ c.coursesNumber }})</span></a
            >
          </li>
        </ul>
        <ul v-else class="list-group">
          <ListItemLoader v-for="i in 7" :key="i" />
        </ul>
      </div>

      <div class="col-md-10 col-sm-12">
        <div
          v-if="!loading && $store.state.paginatedCourses !== null"
          class="row mr-2"
        >
          <div
            v-if="emptyCategory"
            class="mx-auto text-center text-justify mt-5 w-50"
          >
            <h3 class="text-warning">
              <span class="fa fa-exclamation-triangle"></span>
            </h3>
            <h3 class="text-info mx-auto">
              Desole les cours de cette Categorie sont momentanement
              indisponibles. Veuillez reessayer plus tard!
            </h3>
          </div>
          <ArticleCourseComponent
            :course="c"
            v-for="c in getSortedCourses"
            :key="c.id"
          />
        </div>
        <div v-if="loading" class="row">
          <CourseLoader v-for="i in 16" :key="i" />
        </div>
        <nav
          aria-label="Page navigation example"
          v-if="$store.state.totalPages > 1"
        >
          <ul class="pagination justify-content-end">
            <li class="page-item" :class="!hasPrev ? 'disabled' : ''">
              <button
                class="page-link"
                :disabled="!hasPrev"
                @click="() => loadCourse('prev')"
                tabindex="-1"
              >
                Previous
              </button>
            </li>
            <li>
              <span class="page-link border-top-0 border-bottom-0"
                >page {{ $store.state.pageCourse }}/{{
                  $store.state.totalPages
                }}</span
              >
            </li>
            <li class="page-item" :class="!hasNext ? 'disabled' : ''">
              <button
                class="page-link"
                :disabled="!hasNext"
                @click="() => loadCourse('next')"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from "@/components/layouts/Navbar.vue";
import Footer from "@/components/layouts/Footer.vue";
import ArticleCourseComponent from "@/components/shared/courses/ArticleCourseComponent.vue";
import CourseLoader from "@/components/shared/courses/CourseLoader.vue";
import ListItemLoader from "@/components/shared/courses/ListItemLoader.vue";
export default {
  name: "Courses",
  components: {
    Navbar,
    Footer,
    ArticleCourseComponent,
    CourseLoader,
    ListItemLoader,
  },
  data() {
    return {
      courses: [],
      categories: [],
      selectedCategorie: 0,
      hasNext: false,
      hasPrev: false,
      loading: false,
      loadingCategories: false,
      emptyCategory: false,
    };
  },
  computed: {
    getSortedCourses() {
      if (this.selectedCategorie != 0)
        return this.courses.filter(
          (course) => course.category == this.selectedCategorie
        );
      else return this.courses;
    },
  },
  methods: {
    async sortBy(c) {
      this.loading = true;
      if (c > 0)
        await this.$http
          .get(`/courses/category/${c}/?${this.$store.getters.sufixUrl}`)
          .then((response) => {
            this.loading = false;
            this.courses = response.data.categoryCourses;

            if (this.courses.length == 0) {
              this.emptyCategory = true;
            } else {
              this.emptyCategory = false;
            }
          });
      else this.loadCourse(null);
      this.selectedCategorie = c;
    },
    async loadCourse(direction = "next") {
      this.loading = true;
      await this.$store
        .dispatch("changeCoursePage", direction)
        .then((paginatedCourses) => {
          this.hasNext = paginatedCourses.next != null;
          this.hasPrev = paginatedCourses.previous != null;
          this.courses = paginatedCourses.results;
          if (this.courses.length == 0) {
            this.emptyCategory = true;
          } else {
            this.emptyCategory = false;
          }
          console.log(paginatedCourses);
        });
      this.loading = false;
    },
    async loadCategories() {
      this.loadingCategories = true;
      await this.$store.dispatch("loadAllCategories").then((categories) => {
        this.categories = categories;
        this.loadingCategories = false;
      });
    },
  },
  beforeMount() {
    this.$nextTick(function () {
      this.loadCategories();
      this.loadCourse();
    });
  },
};
</script>
<style scoped>
.tag {
  max-height: 30px;
  padding: 0;
}
.tag:hover {
  background: none;
}
.tag a,
.tag a:hover {
  text-decoration: none;
}
.tags {
  margin-left: auto;
  text-align: center;
}
.btn-link:focus {
  border: 0;
  background: #007bff;
  color: white;
}

body {
  overflow-x: hidden;
}
</style>
